@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;




/* site wide */
p, h1, h2, h3, h4, h5{
  color: white;
}

div{
  box-sizing: border-box;
}

@layer utilities {
  .scrollbar {
      scrollbar-color: white;
      scrollbar-width: thin;
  }
      .scrollbar::-webkit-scrollbar {
          width: 5px;
      }
      .scrollbar::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1) !important;

      }
      .scrollbar::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.3) ;
          border-radius: 5px;
      }
}

/* Use this to make base styles */
@layer components{
  .logo{
    @apply w-28 sm:w-32 h-full 
  }
  .title{
    @apply font-bold text-2xl  md:text-5xl
  }
  .nav-pad{
    @apply pt-20 pb-40 md:pb-20 
  }
  .black-box{
    @apply  w-11/12 md:w-full max-w-lg  bg-black bg-opacity-30 rounded-2xl shadow-2xl p-4 md:p-8 mb-4
  }
  .btn{
    @apply bg-blue text-white font-bold rounded-full px-10 py-2 shadow-md hover:scale-105 transform transition-all max-w-max
  }
  .btn2{
    @apply text-white mx-2 border border-white rounded py-1 px-2 cursor-pointer text-sm
  }
  .select{
    @apply text-white bg-black bg-opacity-30 p-4 rounded-md
  }
  .cont{
    @apply px-3 py-5 w-full text-center md:p-10 flex flex-col  mx-auto flex-wrap max-w-screen-lg justify-center 
  }
}

/* chakra ui overrides */

.active-override:active{
  background: #101010 !important;
  --tw-bg-opacity: 0.5;
}
.active-override:hover{
  background: #101010 !important;
  --tw-bg-opacity: 0.5;
}

.active-override:focus{
  background: #101010 !important;
  --tw-bg-opacity: 0.5;
}

/* custom checkboxes for step 1 of profile builder */
.checkbox-custom .chakra-checkbox__control{
  background: transparent !important;
  border-color: white !important;
  color: transparent !important;
  font-size: x-large !important;
  border-radius: 100% !important;
  width: 20px !important;
  height: 20px !important;
}



.checkbox-custom .chakra-checkbox__control[data-checked]{
  background: white !important;
  border-color: white !important;
  color: black !important;
  font-size: x-large !important;
  border-radius: 100% !important;
  width: 20px !important;
  height: 20px !important;
}
